<template>
    <div class='single-date-picker' :style="styles">

        <div class="calendar">
            <!--<div class="small-modal">-->
                <!--<slot name="small-modal">-->
                    <!--<div v-for="(period,index) in periods" :key="index" >-->
                        <!--<div class="time-section">{{period.title}}</div>-->
                    <!--</div>-->

                    <!--<div class="modal-buttons left">ok</div>-->
                    <!--<div class="modal-buttons right">cancel</div>-->
                <!--</slot>-->
            <!--</div>-->
            <div class="header">
                <div style="height:55px;">
                    <template v-if="day != null">
                        <div class="year">
                            <slot name="year-header" :year="dayYear">
                                {{dayYear}}
                            </slot>
                        </div>
                        <div class="title">
                            <slot name="header" :dayName="dayName" :dayNum="dayNum" :monthName="monthName">
                                <div :style="styles" style="cursor: default;">{{dayTitle}}</div>
                            </slot>
                        </div>
                    </template>
                </div>
            </div>

            <transition name="fade" mode="out-in" @after-enter="checkIsYearSelect">


                <template v-if="view=='year-select'">
                    <div class="year-select" id="year-select" :key="1" ref="yearSelect">
                        <div :class="{'selected selected-year':year.isSelected,'not-selectable':!year.isSelectable}"
                             class="year-row" v-for="year in years"
                             :id="'year-'+year.year"
                             :key="'year-'+year.year"
                             @click='selectYear(year)'>
                            {{year.year}}
                        </div>
                    </div>
                </template>

                <template v-else-if="view=='month-select'">
                    <div :key="2">
                        <div class="calendar-controls" style="justify-content:center;align-content: center;">
                            <div class="arrow-icon right" @click='movePreviousYear'
                                 :class="{'not-selectable' : !canGoPrevYear,'is-selectable':canGoPrevYear}"
                            >
                                <slot name="prev-icon" :canGoPrevYear="canGoPrevYear">
                                    &rarr;
                                </slot>
                            </div>

                            <div class='year-title' @click="yearSelect" style="cursor: pointer;" :style="styles">
                                {{ year}}
                            </div>
                            <div class="arrow-icon left" @click='moveNextYear'
                                 :class="{'not-selectable' : !canGoNextYear,'is-selectable':canGoNextYear }"
                            >
                                <slot name="next-icon" :canGoNextYear="canGoNextYear">
                                    &larr;
                                </slot>
                            </div>
                            <div class="cl"></div>
                        </div>

                        <div class="month-select" v-for="(row,rowIndex) in monthArray" :key="rowIndex">
                            <div
                                    v-for="(month,monthIndex) in row"
                                    class="month"
                                    :key="monthIndex"
                                    :class="{'selected':month.isSelected,'today':month.isCurrentMonth,'not-selectable':!month.isSelectable,'is-selectable':month.isSelectable}"
                                    @click='selectMonth(month)'>
                                {{month.label}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else-if="view=='day-select'">


                    <div :key="3">
                        <div style="margin:0 auto;text-align: center;">
                            <div class="calendar-controls" style="justify-content:center;align-content: center;">
                                <div class="arrow-icon right" @click='movePreviousMonth'
                                     :class="{'not-selectable' : !previousMonthComps.canGoBack,'is-selectable':previousMonthComps.canGoBack}">
                                    <slot name="prev-icon">
                                        &rarr;
                                    </slot>
                                </div>
                                <!--<a class='arrow' @click='movePreviousYear'>&lsaquo;</a>-->
                                <div class='year-title' @click="monthSelect" style="cursor: pointer;" :style="styles">
                                    {{ header.month.label }}
                                </div>
                                <div class='arrow-icon left' @click='moveNextMonth'
                                     :class="{'not-selectable' : !nextMonthComps.canGoForward,'is-selectable':nextMonthComps.canGoForward}"
                                >
                                    <slot name="next-icon">
                                        &larr;
                                    </slot>
                                </div>
                                <div class="cl"></div>
                            </div>

                        </div>
                        <div class='weekdays'>
                            <div class='weekday' v-for='(weekday,index) in weekdays' :key="index">
                                {{ weekday.label_1 }}
                            </div>
                        </div>

                        <div class='week' v-for='(week,weekIndex) in weeks' :key="weekIndex">

                            <div
                                    class='day'
                                    :class='{"not-selectable":!day.isSelectable,"is-selectable":day.isSelectable, today: day.isToday,
                                    "not-in-month": !day.inMonth,
                                    "selected": day.isSelected && !day.dragActive, "dragged": day.isDragged}'
                                    v-for='(day,dayIndex) in week'
                                    :key="dayIndex"
                                    @click='selectDay(day)'
                            >
                                <div class="event-indicator" v-if="day.hasEvent"></div>
                                {{ day['day'] }}
                            </div>
                        </div>
                    </div>
                </template>
            </transition>

            <!--<div class='header'>-->
            <!--&lt;!&ndash;<a class='arrow' @click='movePreviousYear'>&laquo;</a>&ndash;&gt;-->
            <!--{{dayName}}-->
            <!--&lt;!&ndash;<a class='arrow' @click='moveNextYear'>&raquo;</a>&ndash;&gt;-->
            <!--</div>-->

            <!--<div class='weekdays'>-->
            <!--<div class="weekday" v-for='weekday in weekdays'>-->
            <!--{{ weekday.label }}-->
            <!--</div>-->
            <!--</div>-->

        </div>
    </div>
</template>
<script>

    import moment from 'moment-jalaali';


    // alert(moment(`1399-01`, 'jYYYY-jMM').startOf('jMonth').day());


    let time = new moment();
    const _daysInMonths = [31, 31, 31, 31, 31, 31, 30, 30, 30, 30, 30, 29];
    const _weekdayLabels = ['شنبه', 'یکشنبه', 'دوشنبه', 'سه شنبه', 'چهار شنبه', 'پنج شنبه', 'جمعه'];
    const _monthLabels = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'];
    // const _today = new Date();


    const _todayComps = {
        year: Number(time.format('jYYYY')),
        month: Number(time.format('jMM')),
        day: Number(time.format('jD')),
        yearMonth: time.format('jYYYY-jMM'),
    };


    export default {
        name: 'advanced-date-picker',
        created() {
            if (this.value != null) {
                //     yearMonth: _todayComps.yearMonth,
                this.mainValue = moment(this.value, this.format);
                this.month = Number(this.mainValue.format('jMM'));
                this.year = Number(this.mainValue.format('jYYYY'));
                this.yearMonth = this.mainValue.format('jYYYY-jMM');
            }

            this.normalizePropValues();
        },

        beforeDestroy() {

        },
        data() {
            return {
                // periods: [
                //     {title: 'صبح', from: '8', to: '12'},
                //     {title: 'ظهر', from: '12', to: '16'},
                //     {title: 'عصر', from: '16', to: '20'},
                //     {title: 'شب', from: '20', to: '24'},
                // ],
                //------------


                minDate: null,
                maxDate: null,
                mainValue: null,
                view: 'day-select',
                day: null,
                month: _todayComps.month,
                yearMonth: _todayComps.yearMonth,
                year: _todayComps.year,
                selected: null,
                selectedMonth: null,
                selectedYear: null
            }
        },

        props: {
            eventArray: {
                default: () => {
                    return []
                }
            },
            styles: {
                default: ''
            },
            value: {
                default: new moment()
            },
            dayKey: {type: String, default: 'label'},
            format: {
                default: 'jYYYY-jMM-jDD'
            },
            outputFormat: {
                default: 'jYYYY-jMM-jDD'
            },
            min: {
                default: null
            },
            max: {
                default: null
            },
            allowedDates: {
                type: Function,
                default: () => true
            }

        },
        computed: {
            canGoNextYear() {
                return this.canSelectYear(moment(this.year + 1, 'jYYYY'));
            },
            canGoPrevYear() {
                return this.canSelectYear(moment(this.year - 1, 'jYYYY'));
            },
            dayTitle() {
                return `${this.dayName}، ${this.dayNum} ${this.dayMonthName}`;
            },
            // dayName() {
            //     if (this.day != null) {
            //         // alert(moment(this.day).day());
            //         let day = (moment(this.day).day() + 1) % 7;
            //         return _weekdayLabels[day];
            //     }
            //     return null;
            // },
            dayNum() {
                if (this.day != null) {
                    return moment(`${this.day.date.format('jYYYY-jMM-jD')}`, 'jYYYY-jMM-jDD').format('jD');
                }
                return null;
            },
            dayYear() {
                if (this.day != null) {
                    return this.day.date.format('jYYYY');
                }
                return null;
            },
            dayMonthName() {
                if (this.day != null) {
                    return _monthLabels[Number(moment(this.day.date).format('jMM')) - 1];
                }
                return null;
            },
            monthName() {
                if (this.day != null) {
                    return _monthLabels[Number(moment(this.month, 'jM').format('jMM')) - 1];
                }
                return null;
            },

            monthArray() {


                let x = [];
                let now = new moment(`${_todayComps.year}-${_todayComps.month}`, 'jYYYY-jMM').unix();

                _monthLabels.forEach((month, index) => {
                    let newindex = Math.floor(index / 3);
                    if (!Array.isArray(x[newindex])) {
                        x[newindex] = [];
                    }

                    let monthIndex = moment(`${this.year}-${index + 1}`, 'jYYYY-jMM').unix();

                    let monthItem = {
                        label: month,
                        date: moment(`${this.year}-${index + 1}`, 'jYYYY-jMM'),
                        isCurrentMonth: monthIndex == now ? true : false,
                        isSelected: false,
                        isSelectable: true,
                        failedConditions: false
                    };


                    monthItem.isSelectable = this.canSelectMonth(monthItem.date);
                    x[newindex].push(monthItem);
                    this.configureMonth(monthItem);
                });
                return x;
            },
            dayName() {
                if (this.day != null) {
                    return _weekdayLabels[this.day.weekday - 1];
                }
                return null;
            },
            // Our component exposes month as 1-based, but sometimes we need 0-based
            monthIndex() {
                return this.month - 1;
            },
            isLeapYear() {
                return moment.jIsLeapYear(this.year);
                // return (this.year % 4 === 0 && this.year % 100 !== 0) || this.year % 400 === 0;
            },
            // Day/month/year components for previous month
            previousMonthComps() {

                let result = {};
                if (this.month === 1) {
                    let month = moment(`${this.year - 1}-12`, 'jYYYY-jMM');
                    result = {
                        days: _daysInMonths[11],
                        month: 12,
                        year: this.year - 1,
                        canGoBack: this.canSelectMonth(month)
                    }
                } else {

                    let month = moment(`${this.year}-${this.month - 1}`, 'jYYYY-jMM');
                    result = {
                        days: (this.month === 3 && this.isLeapYear) ? 29 : _daysInMonths[this.month - 2],
                        month: this.month - 1,
                        year: this.year,
                        canGoBack: this.canSelectMonth(month)
                    };
                }
                return result;
            },
            // Day/month/year components for next month
            nextMonthComps() {
                let result = {};
                if (this.month === 12) {
                    let month = moment(`${this.year + 1}-1`, 'jYYYY-jMM');
                    result = {
                        days: _daysInMonths[0],
                        month: 1,
                        year: this.year + 1,
                        canGoForward: this.canSelectMonth(month)
                    }
                } else {
                    let month = moment(`${this.year}-${this.month + 1}`, 'jYYYY-jMM');
                    result = {
                        days: _daysInMonths[this.month],//(this.month === 2 && this.isLeapYear) ? 29 : _daysInMonths[this.month],
                        month: this.month + 1,
                        year: this.year,
                        canGoForward: this.canSelectMonth(month)
                    };
                }

                return result;
            },
            // State for calendar header (no dependencies yet...)
            months() {
                return _monthLabels.map((ml, i) => ({
                    label: ml,
                    label_1: ml.substring(0, 1),
                    label_2: ml.substring(0, 2),
                    label_3: ml.substring(0, 3),
                    number: i + 1,
                }));
            },
            // State for weekday header (no dependencies yet...)
            weekdays() {
                return _weekdayLabels.map((wl, i) => ({
                    label: wl,
                    label_1: wl.substring(0, 1),
                    label_2: wl.substring(0, 2),
                    label_3: wl.substring(0, 3),
                    number: i + 1,
                }));
            },
            // State for calendar header
            header() {
                const month = this.months[this.monthIndex];
                return {
                    month: month,
                    year: this.year.toString(),
                    shortYear: this.year.toString().substring(2, 4),
                    label: month.label + ' ' + this.year,
                };
            },
            // Returns number for first weekday (1-7), starting from Sunday
            firstWeekdayInMonth() {
                // moment(`1399-01`, 'jYYYY-jMM').day()
                return Number(moment(`${this.year}-${this.monthIndex + 1}`, 'jYYYY-jMM').startOf('jMonth').day() + 2) % 8;
                // return new Date(this.year, this.monthIndex, 1).getDay() + 1;
            },
            // Returns number of days in the current month
            daysInMonth() {
                // Check for February in a leap year

                if (this.month === 12 && this.isLeapYear) return 30;
                // ...Just a normal month
                return _daysInMonths[this.monthIndex];
            },
            weeks() {
                const weeks = [];
                let previousMonth = true, thisMonth = false, nextMonth = false;
                let day = this.previousMonthComps.days - this.firstWeekdayInMonth + 2;
                let month = this.previousMonthComps.month;
                let year = this.previousMonthComps.year;
                // Cycle through each week of the month, up to 6 total
                for (let w = 1; w <= 6 && !nextMonth; w++) {
                    // Cycle through each weekday
                    const week = [];
                    for (let d = 1; d <= 7; d++) {

                        // We need to know when to start counting actual month days
                        if (previousMonth && d >= this.firstWeekdayInMonth) {
                            // Reset day/month/year counters
                            day = 1;
                            month = this.month;
                            year = this.year;
                            // ...and flag we're tracking actual month days
                            previousMonth = false;
                            thisMonth = true;
                        }

                        // Append day info for the current week
                        // Note: this might or might not be an actual month day
                        //  We don't know how the UI wants to display various days,
                        //  so we'll supply all the data we can
                        const dayInfo = {
                            label: (day && thisMonth) ? day.toString() : '',
                            day,
                            weekday: d,
                            week: w,
                            month,
                            year,
                            // date: new Date(year, month - 1, day),
                            date: new moment(`${year} ${month } ${day}`, 'jYYYY jMM jDD'),
                            beforeMonth: previousMonth,
                            afterMonth: nextMonth,
                            inMonth: thisMonth,
                            isToday: day === _todayComps.day && month === _todayComps.month && year === _todayComps.year,
                            isFirstDay: thisMonth && day === 1,
                            isLastDay: thisMonth && day === this.daysInMonth,
                            isSelectable: true,
                            failedConditions: false,
                            hasEvent: false
                        };

                        dayInfo.hasEvent = this.eventArray.includes(dayInfo.date.format('YYYY-MM-DD'));


                        dayInfo.isSelectable = this.isDateInRange(this.minDate, this.maxDate, dayInfo.date);
                        if (!dayInfo.isSelectable) {
                            dayInfo.failedConditions = true;
                        }
                        if (!dayInfo.failedConditions) {
                            dayInfo.isSelectable = this.allowedDates(dayInfo.date.format('jYYYY-jMM-jDD'));
                        }


                        this.configureDay(dayInfo);
                        week.push(dayInfo);

                        // We've hit the last day of the month
                        if (thisMonth && day >= this.daysInMonth) {
                            thisMonth = false;
                            nextMonth = true;
                            day = 1;
                            month = this.nextMonthComps.month;
                            year = this.nextMonthComps.year;
                            // Still in the middle of the month (hasn't ended yet)
                        } else {
                            day++;
                        }
                    }
                    // Append week info for the month
                    weeks.push(week);
                }
                return weeks;
            },
            years() {
                let years = [];
                let start, end;

                if (this.minDate != null) {
                    start = Number(this.minDate.format('jYYYY'))
                } else {
                    start = _todayComps.year - 50;
                }

                if (this.maxDate != null) {
                    end = Number(this.maxDate.format('jYYYY'))
                } else {
                    end = _todayComps.year + 50
                }

                for (let i = start - 5; i <= end + 5; i++) {
                    let year = i;
                    let date = moment(`${year}`, 'jYYYY');
                    let yearObj = {
                        isSelected: year == this.year ? true : false,
                        year: year,
                        date: date,
                        isSelectable: this.canSelectYear(date)
                    };

                    years.push(yearObj);
                    this.$emit('configureYear', yearObj)
                    this.configureYear(yearObj);
                }
                return years;
            },
            hasValue() {
                return this.mainValue;
            },
            valueTime() {
                if (this.hasValue) {
                    let time = new moment(this.mainValue.format('jYYYY-jMM-jDD'), 'jYYYY-jMM-jDD')
                    return time.unix();
                }
                return null;
                // return this.hasValue ? this.value.unix() : null;
            }
        },
        methods: {
            isDateInRange(min, max, date) {
                if (min != null && max == null) {
                    return date.isSameOrAfter(min);
                } else if (min != null && max != null) {
                    return date.isSameOrAfter(min) && date.isSameOrBefore(max);
                } else if (max != null && min == null) {
                    return date.isSameOrBefore(max);
                } else {
                    return true;
                }
            },
            checkIsYearSelect() {
                if (this.view == 'year-select') {
                    this.scrollYears();
                }
            },
            normalizePropValues() {
                if (this.min == 'today') {
                    this.minDate = new moment();

                } else if (this.min != null) {
                    this.minDate = new moment(this.min, this.format);

                }
                if (this.max != null) {
                    this.maxDate = moment(this.max, this.format);
                }
            },
            yearSelect() {
                this.view = 'year-select';
            },
            monthSelect() {

                this.view = 'month-select';
            },
            scrollYears() {
                this.$nextTick(() => {
                    let container = this.$el.querySelector('#year-select');
                    if (container != null) {
                        container.scrollTop = container.scrollHeight / 2 - 120;
                    }

                })

            },

            selectYear(year) {
                if (this.canSelectYear(year.date)) {

                    this.year = year.year;
                    this.selectedYear = year.date;
                    this.$nextTick(() => {
                        this.view = 'month-select';
                    })

                }
                // this.$emit("selectYear", year);
            },

            // selectMonth(month) {
            //     this.selectMonth(month);
            // },

            selectDay(day) {

                if (day.isSelectable) {


                    this.day = day;
                    this.mainValue = day.date;
                    this.selectedMonth = moment(day.date.format('jYYYY-jMM'), 'jYYYY-jMM');
                    this.$emit("input", this.mainValue.format(this.outputFormat));
                }
            },
            moveThisMonth() {
                this.month = _todayComps.month;
                this.year = _todayComps.year;
            },
            moveNextMonth() {
                const {month, year, canGoForward} = this.nextMonthComps;
                if (canGoForward) {
                    this.month = month;
                    this.year = year;
                }

            },
            movePreviousMonth() {
                const {month, year, canGoBack} = this.previousMonthComps;
                if (canGoBack) {
                    this.month = month;
                    this.year = year;
                }
            },
            moveNextYear() {
                if (this.canSelectYear(moment(this.year + 1, 'jYYYY'))) {
                    this.year++;
                }
            },
            movePreviousYear() {
                if (this.canSelectYear(moment(this.year - 1, 'jYYYY'))) {
                    this.year--;
                }

            },

            //////////////

            configureMonth(month) {
                if (this.selectedMonth != null) {
                    month.isSelected = month.date.unix() === this.selectedMonth.unix();
                    if (month.isSelected) {
                        this.month = Number(month.date.format('jM'));
                    }
                }
            },
            configureYear(year) {
                if (this.selectedYear != null) {
                    year.isSelected = year.date.unix() === this.selectedYear.unix();
                }
                if (year.isSelected) {
                    this.selectedYear = year.date;
                    // this.$emit('inputYear', this.selectedYear);
                }
            },
            canSelectMonth(date) {
                //separate conds

                let minTrim;
                let maxTrim;

                if (this.minDate != null) {
                    minTrim = new moment(this.minDate.format('jYYYY-jMM'), 'jYYYY-jMM').unix();
                }
                if (this.maxDate != null) {
                    maxTrim = new moment(this.maxDate.format('jYYYY-jMM'), 'jYYYY-jMM').unix();
                }


                if (this.minDate != null && this.maxDate != null) {
                    return date.unix() >= minTrim && date.unix() <= maxTrim;
                } else if (this.minDate != null && this.maxDate == null) {
                    return date.unix() >= minTrim;
                } else if (this.minDate == null && this.maxDate != null) {
                    return date.unix() <= maxTrim;
                } else if (this.minDate == null && this.maxDate == null) {
                    return true;
                }

            },
            canSelectYear(date) {
                let year = Number(date.format('jYYYY'));

                if (this.minDate != null) {
                    let minYear = Number(this.minDate.format('jYYYY'));
                    let minDiff = year - minYear;
                    if (minDiff < 0) {
                        return false;
                    }
                }

                if (this.maxDate != null) {
                    let maxYear = Number(this.maxDate.format('jYYYY'));
                    let maxDiff = year - maxYear;
                    if (maxDiff > 0) {
                        return false;
                    }
                }
                return true;
            },
            selectMonth(month) {

                if (!this.canSelectMonth(month.date)) return;

                let selected = month.isSelected ? null : month.date;
                this.selectedMonth = selected;
                this.$nextTick(() => {
                    this.view = 'day-select';
                });

            },

            configureDay(day) {
                day.isSelected = day.date.unix() === this.valueTime;

                if (day.isSelected) {
                    this.day = day;
                }
            },

        },
        watch: {

            value(newval) {

                if (newval != null && newval != '') {
                    this.mainValue = moment(this.value, this.format);
                    this.month = Number(this.mainValue.format('jMM'));
                    this.year = Number(this.mainValue.format('jYYYY'));
                    this.yearMonth = this.mainValue.format('jYYYY-jMM');
                } else {

                    this.mainValue = null;
                }

            },
            view(input) {
                if (input == 'year-select') {
                    this.scrollYears();
                }
            }
        }
    }
</script>

<style scoped lang="sass">

    $themeColor: #43378c

    $headerPadding: 1rem 1rem
    $headerBorderWidth: 1px
    $headerBorderStyle: solid
    $headerBorderColor: #aaaaaa
    $headerBackground: $themeColor
    $headerColor: white

    $weekdayPadding: 0.4rem 0
    $weekdayColor: #7a7a7a
    $weekdayBorderWidth: 1px
    $weekdayBorderStyle: solid
    $weekdayBorderColor: #aaaaaa
    $weekdayBackground: #eaeaea

    $dayColor: #3a3a3a
    $dayBorder: solid 1px #aaaaaa
    $dayBackgroundColor: white
    $dayWidth: 30px
    $dayMargin: 2px
    $dayHeight: 30px
    $dayRadius: 50px

    $monthWidth: 75.5px
    $monthMargin: 2px
    $monthHeight: 35px
    $monthRadius: 4px

    $todayColor: white
    $todayBorder: 1px solid #1e6fb8
    $todayBackgroundColor: $themeColor

    $notInMonthColor: #cacaca
    $notInMonthBackgroundColor: #fafafa

    $selectedColor: #fafafa
    $selectedBackgroundColor: #1e6fb8

    $draggedColor: #fafafa
    $draggedBackgroundColor: #8ca7c2

    *
        box-sizing: border-box

    .calendar
        display: flex
        flex-direction: column
        position: relative

    .single-date-picker

        border-radius: 10px

    .header
        display: flex
        border-top-right-radius: 4px
        border-top-left-radius: 4px
        justify-content: stretch
        align-items: center
        color: $headerColor
        padding: $headerPadding
        font-size: 1rem
        background-color: $headerBackground
        direction: rtl
        font-weight: bold

        =pointer()
            cursor: pointer
            &:hover
                color: #dcdcdc

        .arrow
            +pointer
            padding: 0 0.4em 0.2em 0.4em
            font-size: 1.8rem
            font-weight: 500
            user-select: none
            flex-grow: 0

        .title
            +pointer
            flex-grow: 1
            font-size: 1.2rem
            text-align: center

        .year
            line-height: 10px
            margin: 2px 0
            opacity: .7
            font-size: .9em

    .weekdays
        display: flex
        justify-content: space-around
        padding: 0 10px

    .weekday
        width: $dayWidth
        font-size: .9em
        text-align: center
        display: flex
        margin: $dayMargin
        justify-content: center
        align-items: center
        padding: $weekdayPadding
        color: $weekdayColor
        cursor: default

    .week
        display: flex
        flex-direction: row
        justify-content: space-around
        padding: 0 10px

    .day
        width: $dayWidth
        height: $dayHeight
        display: flex
        justify-content: center
        align-items: center

        font-size: .8em
        font-weight: 600
        color: $dayColor
        background-color: $dayBackgroundColor

        margin: $dayMargin
        border-radius: $dayRadius
        position: relative

        -webkit-transition: color .2s ease-in, background-color .2s ease-in
        -moz-transition: color .2s ease-in, background-color .2s ease-in
        -ms-transition: color .2s ease-in, background-color .2s ease-in
        -o-transition: color .2s ease-in, background-color .2s ease-in
        transition: color .2s ease-in, background-color .2s ease-in
        &:hover
            background-color: rgba(0, 0, 0, .05)
            &.selected
                &:hover
                    background-color: #1e8fe8

    .is-selectable
        cursor: pointer

    .today
        font-weight: 700
        border: $todayBorder

    .not-in-month
        color: $notInMonthColor
        background-color: $notInMonthBackgroundColor

    .not-selectable
        color: $notInMonthColor

    .selected
        color: $selectedColor
        background-color: $selectedBackgroundColor

    .dragged
        color: $draggedColor
        background-color: $draggedBackgroundColor

    .sidebar
        margin-left: 20px

        h4
            font-size: 1.2rem
            font-weight: 500
            margin-top: 20px
            margin-bottom: 10px

        .option
            margin-top: 5px

    .current-month
        margin: 10px auto
        justify-content: stretch
        direction: rtl
        align-items: center

        =pointer()
            cursor: pointer
            &:hover
                color: #dcdcdc

        .arrow
            +pointer
            padding: 0 0.4em 0.2em 0.4em
            font-size: 1.8rem
            user-select: none
            flex-grow: 0

    .month-select
        display: flex
        flex-direction: row
        justify-content: space-around

    .year-select
        width: 100%
        height: 240px
        overflow-y: scroll

    .year-row
        display: flex
        width: 100%

        height: 40px
        justify-content: center
        align-items: center
        cursor: pointer
        -webkit-transition: all .3s ease-in-out
        -moz-transition: all .3s ease-in-out
        -ms-transition: all .3s ease-in-out
        -o-transition: all .3s ease-in-out
        transition: all .3s ease-in-out

        &.not-selectable
            color: #cacaca
        &:hover
            background-color: rgba(0, 0, 0, .1)

        &.selected
            background-color: white
            color: $selectedBackgroundColor
            font-weight: bolder
            font-size: 1.3em

            &:hover
                background-color: rgba(0, 0, 0, .1)

    .month
        width: $monthWidth
        height: $monthHeight
        padding: 5px
        display: flex
        justify-content: center
        align-items: center
        color: $dayColor
        margin: $dayMargin
        border-radius: $monthRadius
        font-size: .8em
        font-weight: 600
        -webkit-transition: color .1s ease-in-out, background-color .1s ease-in-out
        -moz-transition: color .1s ease-in-out, background-color .1s ease-in-out
        -ms-transition: color .1s ease-in-out, background-color .1s ease-in-out
        -o-transition: color .1s ease-in-out, background-color .1s ease-in-out
        transition: color .1s ease-in-out, background-color .1s ease-in-out

        &:hover
            background-color: rgba(0, 0, 0, .1)

        &.selected
            &:hover
                background-color: #1e8fe8
        &.not-selectable
            color: #cacaca
        &.is-selectable
            cursor: pointer

    .selected
        color: white

    .fade-enter-active, .fade-leave-active
        transition: opacity .3s

    .fade-enter, .fade-leave-to
        opacity: 0

    .calendar-controls
        width: 100%
        position: relative
        text-align: center
        height: 40px
        margin-bottom: 5px
        div
            display: inline-block
            &.is-selectable
                cursor: pointer
        .year-title
            margin-top: 15px

    .arrow-icon
        width: 30px
        height: 30px
        background-color: rgba(0, 0, 0, .1)
        -webkit-transition: background-color .2s ease-in-out
        -moz-transition: background-color .2s ease-in-out
        -ms-transition: background-color .2s ease-in-out
        -o-transition: background-color .2s ease-in-out
        transition: background-color .2s ease-in-out
        font-size: 1.2em

        &.left
            position: absolute
            left: 0
            top: 5px
        &.right
            position: absolute
            right: 0
            top: 5px

        &:hover
            background-color: rgba(0, 0, 0, .2)

        -webkit-border-radius: 30px
        -moz-border-radius: 30px
        border-radius: 30px
        margin: 5px 10px
        &.not-selectable
            background-color: white

    .event-indicator
        width: 7px
        height: 7px
        border-radius: 10px
        background-color: rgba(250, 120, 100, 1)
        position: absolute
        bottom: 1px

    .small-modal
        height: 60%
        position: absolute
        background-color: white
        width: 60%
        top: 20%
        left: 20%
        box-shadow: 0 10px 25px -10px rgba(0, 0, 0, .3)
        border-radius: 4px

        z-index: 1

    .modal-buttons
        width: 50%
        bottom: 0
        text-align: center
        height: 30px
        line-height: 35px
        border-top: 1px solid rgba(0, 0, 0, .1)

        cursor: pointer
        &.left
            border-right: 1px solid rgba(0, 0, 0, .1)
            left: 0
            color: rgba(0, 200, 100, 1)
            border-bottom-left-radius: 4px

        &.right
            right: 0
            border-bottom-right-radius: 4px
            color: rgba(240, 0, 50, 1)

        position: absolute


    .time-section

        position: relative
</style>